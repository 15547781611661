import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import {
  STORENAME
} from './utils/config'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    authInfo: Cookies.get(`${STORENAME}auth`) ? JSON.parse(Cookies.get(`${STORENAME}auth`)) : null,
    activeNav: '1',
    breadcrumb: [{
      t: '商品管理',
      r: 'goods-list'
    }]
  },
  mutations: {
    updateAuthInfo(state, data) {
      state.authInfo = data
      if (data) {
        Cookies.set(`${STORENAME}auth`, JSON.stringify(data))
      } else {
        Cookies.remove(`${STORENAME}auth`)
      }
    },
    updateActiveNav(state, data) {
      state.activeNav = data || '1'
    },
    updateBreadcrumb(state, data) {
      state.breadcrumb = data || [{
        t: '商品管理',
        r: 'goods-list'
      }]
    }
  },
  actions: {}
})

export default store