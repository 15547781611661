import { API_GROUP } from '../requests/api'
import Request from '../requests'

export async function groupListService(data) {
  return await Request.post({
    url: API_GROUP.list,
    data
  })
}

export async function groupInfoService(data) {
  return await Request.post({
    url: API_GROUP.info,
    data
  })
}

export async function groupUpdateService(data) {
  return await Request.post({
    url: API_GROUP.update,
    data
  })
}