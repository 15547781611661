<template>
  <div class="app-page">
    
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return { }
  },
  mounted () {
    this.$router.push({
      name: 'goods-list',
      query: {
        a: '1',
        b: this.$encode([{ t: '商品管理', r: 'goods-list' }]),
        t: Date.now()
      }
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
