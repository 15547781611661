import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import {
  Base64
} from 'js-base64'
import './styles/element-ui/index.css'
import './styles/main.scss'

Vue.config.productionTip = false

Vue.prototype.$TMap = window.TMap

Vue.prototype.$encode = (data = []) => {
  return Base64.encode(JSON.stringify(data))
}

Vue.prototype.$decode = (data = '') => {
  if (!data) return []
  return JSON.parse(Base64.decode(data))
}

Vue.use(ElementUI, {
  size: 'small'
})

router.beforeEach((to, from, next) => {
  if (store.state.authInfo || to.path === '/login') {
    if (to.query.a) {
      store.commit('updateActiveNav', to.query.a)
    }
    let title = '乐读图书管理后台'
    if (to.query.b) {
      const breadcrumb = Vue.prototype.$decode(to.query.b)

      for (let index = breadcrumb.length - 1; index >= 0; index--) {
        const element = breadcrumb[index]
        title = `${element.t}-${title}`
      }
      store.commit('updateBreadcrumb', breadcrumb)
    }
    document.title = title
    next()
  } else {
    document.title = '乐读图书管理后台'
    next({
      path: '/login'
    })
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')