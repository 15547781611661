<template>
  <div class="app-page">
    <Header :show-back="false">
      <template slot="append">
        <el-button
          type="primary"
          @click="handleSave"
          :loading="loading"
        >保存</el-button>
      </template>
    </Header>
    <div class="page-content">
      <div class="block-title flex-align">轮播图管理</div>
      <div class="banner-wrapper">
        <div class="banner-item" v-for="(item, index) in banner" :key="`${item.file.url}${index + 1}`">
          <el-upload
            class="item-upload banner-upload"
            action="#"
            list-type="picture-card"
            accept="image/png,image/jpg,image/jpeg,image/gif"
            :show-file-list="false"
            :limit="9 - banner.length"
            :multiple="true"
            :auto-upload="false"
            :on-change="handleChangeImage.bind(this, index)">
            <div class="upload-image background-image" :style="{ backgroundImage: 'url(' + item.file.url + ')'}"></div>
            <div class="upload-delete flex-align-justify" @click.stop="handleRemoveImage(index)">删除</div>
          </el-upload>
          <el-radio-group class="item-radio" v-model="item.type" @input="handleChangeType($event, index)">
            <el-radio :label="1">书单</el-radio>
            <el-radio :label="2">商品</el-radio>
          </el-radio-group>
          <el-select
            class="item-select"
            v-model="item.id"
            placeholder="请输入关键字"
            filterable
            remote
            clearable
            :remote-method="searchFunc.bind(this, item.type)"
            :loading="searchLoading">
            <template v-if="item.type === 1">
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </template>
            <template v-else-if="item.type === 2">
              <el-option
                v-for="item in goodsList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </template>
          </el-select>
        </div>
        <div class="banner-item">
          <el-upload
            v-if="banner.length < 9"
            class="item-upload banner-upload"
            action="#"
            list-type="picture-card"
            accept="image/png,image/jpg,image/jpeg,image/gif"
            :show-file-list="false"
            :limit="9 - banner.length"
            :multiple="true"
            :auto-upload="false"
            :on-change="handleChangeImage.bind(this, -1)">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
          <el-radio-group class="item-radio" :value="1">
            <el-radio :label="1" disabled>书单</el-radio>
            <el-radio :label="2" disabled>商品</el-radio>
          </el-radio-group>
          <el-select class="item-select" value="" placeholder="请输入关键字" disabled></el-select>
        </div>
      </div>
      <div class="block-title flex-align">书单卡片管理</div>
      <div class="book-wrapper">
        <div class="book-item flex-align" v-for="(item, index) in card" :key="index">
          <el-input class="item-input" placeholder="请输入" v-model="item.name">
            <template slot="prepend">标题</template>
          </el-input>
          <el-input class="item-input" placeholder="请输入" v-model="item.des">
            <template slot="prepend">副标题</template>
          </el-input>
          <el-input class="item-input" placeholder="请输入" v-model="item.tag">
            <template slot="prepend">标签</template>
          </el-input>
          <el-select
            class="item-input"
            v-model="item.group_id"
            placeholder="关联书单，输入关键字"
            filterable
            remote
            clearable
            :remote-method="searchFunc.bind(this, 1)"
            :loading="searchLoading">
            <el-option
              v-for="item in groupList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-button
            class="item-button"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            circle
            @click="handleAddGroup(index)"
          ></el-button>
          <el-button
            class="item-button"
            type="danger"
            size="mini"
            icon="el-icon-delete"
            circle
            @click="handleDeleteGroup(index)"
            v-if="card.length > 1"
          ></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { groupListService } from '../services/group'
import { goodsListService } from '../services/goods'
import { mobileInfoService, mobileUpdateService } from '../services/mobile'
import { uploadService } from '../services/common'

import Header from '../components/Header.vue'

export default {
  name: 'Mobile',
  components: { Header },
  data () {
    return {
      banner: [],
      card: [{ name: '', des: '', tag: '', group_id: '' }],
      searchLoading: false,
      groupList: [],
      goodsList: [],
      loading: false
    }
  },
  computed: mapState(['authInfo']),
  methods: {
    async fetchData () {
      const goodsRes = await goodsListService({ page: 1, page_size: 20, status: '1' })
      this.goodsList = Array.isArray(goodsRes?.data?.items) ? goodsRes.data.items : []
      const groupRes = await groupListService({ page: 1, page_size: 20 })
      this.groupList = Array.isArray(groupRes?.data?.items) ? groupRes.data.items : []
      const res = await mobileInfoService()
      if (res?.data) {
        res.data.banner = Array.isArray(res.data?.banner) ? res.data.banner: []
        this.banner = res.data.banner.map(item => {
          const type = parseInt(item.type)
          if (type === 1) {
            const index = this.groupList.findIndex(itm => {
              return itm.id === item.id
            })
            if (index < 0) this.groupList.push({ id: item.id, name: item.name })
          } else if (type === 2) {
            const index = this.goodsList.findIndex(itm => {
              return itm.id === item.id
            })
            if (index < 0) this.goodsList.push({ id: item.id, name: item.name })
          }
          return {
            id: item.id,
            type,
            file: { type: 'net', url: `${this.authInfo.file_url}/${item.url}` }
          }
        })
        this.card = Array.isArray(res.data?.card) ? res.data.card: [{ name: '', des: '', tag: '', group_id: '' }]
        this.card.map(item => {
          const index = this.groupList.findIndex(itm => {
            return itm.id === item.group_id
          })
          if (index < 0) this.groupList.push({ id: item.group_id, name: item.group_name })
        })
      }
    },
    handleRemoveImage (index) {
      this.banner.splice(index, 1)
    },
    handleChangeImage (index, file) {
      if (index < 0) {
        this.banner.push({ file, type: 1, id: '' })
      } else {
        const data = this.banner[index]
        this.banner.splice(index, 1, { ...data, file })
      }
    },
    handleChangeType (value, index) {
      this.banner[index].id = ''
    },
    async searchFunc (type, name = '') {
      if (name) {
        this.searchLoading = true
        let res = null
        if (type === 1) {
          res = await groupListService({ name })
        } else {
          res = await goodsListService({ name, status: '1' })
        }
        this.searchLoading = false
        if (res && Array.isArray(res?.data?.items)) {
          let arr = []
          res.data.items.map(item => {
            let idx = -1
            if (type === 1) {
              idx = this.groupList.findIndex(itm => {
                return itm.id === item.id
              })
            } else {
              idx = this.goodsList.findIndex(itm => {
                return itm.id === item.id
              })
            }
            if (idx < 0) arr.push(item)
          })
          if (type === 1) {
            this.groupList = [...arr, ...this.groupList]
          } else {
            this.goodsList = [...arr, ...this.goodsList]
          }
        }
      }
    },
    handleAddGroup (index) {
      this.card.splice(index + 1, 0 , { name: '', des: '', tag: '', group_id: '' })
    },
    handleDeleteGroup (index) {
      this.card.splice(index, 1)
    },
    async handleSave () {
      if (this.banner.length === 0) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请至少添加一张轮播图'
        })
        return
      }
      for (let index = 0; index < this.banner.length; index++) {
        const element = this.banner[index]
        if (!element.file.url) {
          this.$notify({
            title: '警告',
            type: 'warning',
            message: `请上传第${index + 1}个轮播图的图片`
          })
          return
        }
        if (!element.id) {
          this.$notify({
            title: '警告',
            type: 'warning',
            message: `请选择第${index + 1}个轮播图的关联${element.type === 1 ? '书单' : '商品'}`
          })
          return
        }
      }
      for (let index = 0; index < this.card.length; index++) {
        const element = this.card[index]
        if (!element.name) {
          this.$notify({
            title: '警告',
            type: 'warning',
            message: `请输入第${index + 1}个书单卡片的标题`
          })
          return
        }
        if (!element.des) {
          this.$notify({
            title: '警告',
            type: 'warning',
            message: `请输入第${index + 1}个书单卡片的副标题`
          })
          return
        }
        if (!element.tag) {
          this.$notify({
            title: '警告',
            type: 'warning',
            message: `请输入第${index + 1}个书单卡片的标签`
          })
          return
        }
        if (!element.group_id) {
          this.$notify({
            title: '警告',
            type: 'warning',
            message: `请选择第${index + 1}个书单卡片的关联书单`
          })
          return
        }
      }
      this.loading = true
      let banner = JSON.parse(JSON.stringify(this.banner))
      for (let index = 0; index < banner.length; index++) {
        const element = banner[index]
        if (element.file?.type === 'net') {
          element.url = element.file.url
        } else {
          const res = await uploadService({ file: this.banner[index].file.raw })
          if (res && res?.data?.url) {
            element.url = res.data.url
          } else if (res && (!res.data || !res.data.url)) {
            this.$notify({
              title: '警告',
              type: 'warning',
              message: '上传图片出错'
            })
            this.loading = false
            return
          } else {
            return
          }
        }
        delete banner[index].file
      }
      const reg = new RegExp(`${this.authInfo.file_url}/`, 'g')
      banner.map(item => {
        item.url = item.url.replace(reg, '')
      })
      const res = await mobileUpdateService({ banner, card: this.card })
      this.loading = false
      if (res) {
        this.$notify({
          title: '提示',
          type: 'success',
          message: '操作成功'
        })
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.fetchData()
  }
}
</script>

<style lang="scss">
.block-title{
  height: 40px;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 15px;
  .title-button{
    margin-left: 40px;
  }
}
.banner-wrapper{
  white-space: nowrap;
  padding: 15px 0;
  overflow-x: auto;
  margin-bottom: 20px;
  .banner-item{
    display: inline-block;
    margin-right: 20px;
    overflow: hidden;
    &:last-child{
      margin-right: 0;
    }
    .item-upload{
      width: 200px;
      height: 100px;
      line-height: 104px;
      position: relative;
      overflow: hidden;
      &:hover{
        border-color: #409EFF;
        .upload-delete{
          transform: translateY(0);
        }
      }
      .upload-image{
        width: 100%;
        height: 100%;
      }
      .upload-delete{
        position: absolute;
        width: 100%;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
        z-index: 10;
        color: #ffffff;
        cursor: pointer;
        font-size: 12px;
        transition: all 0.15s;
        transform: translateY(100%);
        overflow: hidden;
      }
    }
    .item-radio{
      display: block;
      margin-top: 15px;
    }
    .item-select{
      display: block;
      width: 200px;
      margin-top: 15px;
    }
  }
}
.book-wrapper{
  margin-bottom: 20px;
  .book-item{
    margin-bottom: 20px;
    .item-input{
      margin-right: 10px;
    }
  }
}
</style>