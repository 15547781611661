<template>
  <div class="app-page">
    <Header>
      <template slot="append">
        <el-button type="primary" :loading="loading" @click="handleSubmit">保存</el-button>
      </template>
    </Header>
    <div class="page-content">
      <el-form class="page-form" ref="form" :model="groupData" label-width="120px">
        <el-form-item label="名称" required>
          <el-input class="input-big" v-model="groupData.name"></el-input>
        </el-form-item>
        <el-form-item label="搜索图书">
          <el-select
            v-model="groupData.goods_id"
            multiple
            filterable
            remote
            collapse-tags
            placeholder="请输入关键词"
            style="width: 400px;"
            :remote-method="searchGoods"
            :loading="searchLoading"
            @change="handleChangeGoods">
            <el-option
              v-for="item in goodsList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="已选图书">
          <el-table
            :data="goodsCheck"
            border
            style="width: 540px">
            <el-table-column
              prop="date"
              label="图片"
              width="80">
              <template slot-scope="scope">
                <el-image
                  style="width: 36px; height: 50px"
                  :src="scope.row.pic[0]" 
                  :preview-src-list="scope.row.pic">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="名称">
            </el-table-column>
            <el-table-column
              prop="stock"
              label="库存"
              width="100">
            </el-table-column>
            <el-table-column
              prop="price_true"
              label="成本价"
              width="100">
            </el-table-column>
            <el-table-column
              label="操作"
              width="100">
              <template slot-scope="scope">
                <el-button @click="handleDelete(scope.$index)" type="text" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Header from '../../components/Header.vue'
import { goodsListService } from '../../services/goods'
import { groupInfoService, groupUpdateService } from '../../services/group'
import { formatNumber } from '../../utils/func'

export default {
  name: 'GoodsUpdate',
  components: { Header },
  data () {
    return {
      groupData: {
        name: '',
        goods_id: []
      },
      goodsList: [],
      searchLoading: false,
      goodsCheck: [],
      loading: false
    }
  },
  computed: mapState(['authInfo']),
  methods: {
    async fetchData () {
      const goodsRes = await goodsListService({ page: 1, page_size: 20, status: '1' })
      const list = this.formatList(Array.isArray(goodsRes?.data?.items) ? goodsRes.data.items : [])
      if (this.$route.query?.id) {
        const infoRes = await groupInfoService({ group_id: this.$route.query.id })
        
        if (infoRes?.data) {
          infoRes.data.goods_list = Array.isArray(infoRes.data.goods_list) ? infoRes.data.goods_list : []
          infoRes.data.goods_list = this.formatList(infoRes.data.goods_list)

          this.goodsCheck = [...infoRes.data.goods_list]

          this.goodsList = [...infoRes.data.goods_list]
          
          list.map(item => {
            const index = this.goodsList.findIndex(goods => {
              return goods.id === item.id
            })
            if (index < 0) this.goodsList.push(item)
          })

          this.groupData = {
            name: infoRes.data.name,
            goods_id: infoRes.data.goods_list.map(item => {
              return item.id
            })
          }
        }
      } else {
        this.goodsList = list
      }
    },
    formatList (arr = []) {
      return arr.map(item => {
        item.stock = parseInt(item.stock || 0)
        item.price_now = formatNumber(item.price_now)
        item.price_old = formatNumber(item.price_old)
        item.price_true = formatNumber(item.price_true)
        item.in_home = item.in_home + '' === '1' ? '[success]是' : '[warning]否'
        item.pic = item.pic && JSON.parse(item.pic) ? JSON.parse(item.pic) : []
        item.pic = item.pic.map((img) => {
          return `${this.authInfo.file_url}/${img}`
        })
        item.name = `${item.name},成本价${item.price_true},库存${item.stock}${item.channel ? `,来源${item.channel}` : ''}`
        return item
      })
    },
    async searchGoods (name = '') {
      if (name) {
        this.searchLoading = true
        const res = await goodsListService({ name, status: '1' })
        this.searchLoading = false
        if (res && Array.isArray(res?.data?.items)) {
          let arr = []
          res.data.items.map(item => {
            item.stock = parseInt(item.stock || 0)
            item.price_now = formatNumber(item.price_now)
            item.price_old = formatNumber(item.price_old)
            item.price_true = formatNumber(item.price_true)
            item.in_home = item.in_home + '' === '1' ? '[success]是' : '[warning]否'
            item.pic = item.pic && JSON.parse(item.pic) ? JSON.parse(item.pic) : []
            item.pic = item.pic.map((img) => {
              return `${this.authInfo.file_url}/${img}`
            })
            const index = this.goodsList.findIndex(goods => {
              return goods.id === item.id
            })
            if (index < 0) arr.push(item)
          })
          this.goodsList = [...arr, ...this.goodsList]
        }
      }
    },
    handleChangeGoods (value) {
      let arr = []
      value.map(item => {
        const index = this.goodsList.findIndex(goods => {
          return goods.id === item
        })
        if (index >= 0) arr.push(this.goodsList[index])
      })
      this.goodsCheck = arr
    },
    handleDelete (index) {
      this.groupData.goods_id.splice(index, 1)
      this.goodsCheck.splice(index, 1)
    },
    async handleSubmit () {
      if (!this.groupData.name) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入名称'
        })
        return
      }
      if (this.groupData.goods_id.length === 0) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请选择图书'
        })
        return
      }
      this.loading = true
      const res = await groupUpdateService({ ...this.groupData, group_id: this.$route.query?.id || '' })
      if (res) {
        this.$router.go(-1)
      }
      this.loading = false
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
.page-form{
  .input-big{
    width: 400px;
  }
}
</style>
