import {
  API_MOBILE
} from '../requests/api'
import Request from '../requests'

export async function mobileInfoService(data) {
  return await Request.post({
    url: API_MOBILE.info,
    data
  })
}

export async function mobileUpdateService(data) {
  return await Request.post({
    url: API_MOBILE.update,
    data
  })
}