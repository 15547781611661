export const SEARCHCONFIG = [
  {
    key: 'order_num',
    type: 'input',
    label: '单号',
    value: ''
  },
  {
    key: 'name',
    type: 'input',
    label: '商品名称',
    value: ''
  },
  {
    key: 'member_phone',
    type: 'input',
    label: '客户手机',
    value: ''
  },
  {
    key: 'inviter_phone',
    type: 'input',
    label: '合伙人手机',
    value: ''
  },
  {
    key: 'detail_status',
    type: 'select',
    label: '状态',
    value: '',
    options: [{
      label: '全部',
      value: ''
    },
    {
      label: '未支付',
      value: '100'
    },
    {
      label: '待发货',
      value: '200'
    },
    {
      label: '待收货',
      value: '300'
    },
    {
      label: '已完成',
      value: '400'
    },
    {
      label: '已退货',
      value: '500'
    }]
  },
  {
    key: ['start_time', 'end_time'],
    type: 'datetimerange',
    label: '时间',
    value: ''
  }
]

export const TABLECONFIG = [
  {
    type: 'image',
    label: '商品图片',
    prop: 'goods_pic',
    width: 100
  },
  {
    type: 'text',
    label: '商品名称',
    prop: 'goods_name',
    width: 200
  },
  {
    type: 'text',
    label: '客户手机',
    prop: 'member_phone',
    width: 120
  },
  {
    type: 'text',
    label: '单号',
    prop: 'order_num',
    width: 180
  },
  {
    type: 'tag',
    label: '状态',
    prop: 'detail_status_txt'
  },
  {
    type: 'text',
    label: '实付金额',
    prop: 'amount'
  },
  {
    type: 'text',
    label: '支付方式',
    prop: 'pay_type_txt',
    width: 100
  },
  {
    type: 'text',
    label: '团长',
    prop: 'inviter_phone'
  },
  {
    type: 'text',
    label: '团长提成',
    prop: 'inviter_money'
  },
  {
    type: 'text',
    label: '快递信息',
    prop: 'express_info',
    width: 120,
  },
  {
    type: 'text',
    label: '地址信息',
    prop: 'address',
    width: 200
  },
  {
    type: 'text',
    label: '下单时间',
    prop: 'created_at',
    width: 150
  },
  {
    type: 'slot',
    label: '操作',
    width: 120,
    fixed: true
  },
]