<template>
  <div class="page-index flex">
    <div class="page-nav flex-column">
      <div class="nav-title flex-align">乐读图书</div>
      <div class="nav-content flex-one">
        <el-menu
          class="page-nav"
          :default-active="activeNav"
          background-color="#304156"
          text-color="#bfcbd9"
          active-text-color="#1890ff"
        >
          <template v-for="(nav, row) in navConfig">
            <el-submenu
              :key="row"
              :index="`${row + 1}`"
              v-if="Array.isArray(nav.sub) && nav.sub.length > 0"
            >
              <template slot="title">
                <i :class="`el-icon-${nav.icon}`"></i>
                <span>{{ nav.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="(item, col) in nav.sub"
                  :key="col"
                  :index="`${row + 1}-${col + 1}`"
                  @click="handleToPage(item, `${row + 1}-${col + 1}`)"
                  >{{ item.title }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item
              :key="nav.title"
              :index="`${row + 1}`"
              @click="handleToPage(nav, `${row + 1}`)"
              v-else
            >
              <i :class="`el-icon-${nav.icon}`"></i>
              <span slot="title">{{ nav.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
    <div class="flex-one flex-column overflow-hidden">
      <div class="page-header flex-align">
        <div class="page-breadcrumb flex-one flex-align" separator="/">
          <div
            class="breadcrumb-item"
            v-for="(item, index) in breadcrumb"
            :key="index"
            @click="handleBreadcrumb(index)"
          >
            {{ item.t }}
          </div>
        </div>
        <div>
          欢迎
          <span class="header-user">{{
            username.replace(/(\d{3})\d{4}(\d{4})/g, "$1****$2")
          }}</span>
          登录
        </div>
        <el-button
          class="logout-button"
          type="danger"
          icon="el-icon-switch-button"
          title="退出登录"
          circle
          plain
          @click="handleLogout"
        ></el-button>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { STORENAME } from "../utils/config";

export default {
  name: "Index",
  data() {
    return {
      navConfig: [
        {
          title: "商品管理",
          icon: "goods",
          router: "goods-list",
        },
        {
          title: "书单管理",
          icon: "notebook-1",
          router: "group-list",
        },
        {
          title: "移动端管理",
          icon: "mobile",
          router: "mobile",
        },
        {
          title: "订单列表",
          icon: "s-order",
          router: "order-list",
        },
        {
          title: "会员列表",
          icon: "s-custom",
          router: "member-list",
        },
        {
          title: "分享审核管理",
          icon: "s-check",
          router: "check-list",
        },
        // {
        //   title: '分销统计',
        //   icon: 's-data',
        //   router: 'distribution-statistics'
        // },
        // {
        //   title: '优惠券',
        //   icon: 's-ticket',
        //   router: 'goods-ticket'
        // },
        // {
        //   title: '满减活动',
        //   icon: 's-marketing',
        //   router: 'goods-activity'
        // },
        // {
        //   title: '设置',
        //   icon: 's-tools',
        //   router: 'setting'
        // },
      ],
      username: localStorage.getItem(`${STORENAME}phone`) || "",
    };
  },
  computed: {
    ...mapState(["activeNav", "breadcrumb", "authInfo"]),
  },
  methods: {
    handleLogout() {
      this.$router.replace({ name: "login" });
    },
    handleToPage(opts = {}, active) {
      const { title, router } = opts;
      this.$router.push({
        name: router,
        query: {
          a: active,
          b: this.$encode([{ t: title, r: router }]),
          t: Date.now(),
        },
      });
    },
    handleBreadcrumb(index) {
      let query = {
        a: this.$route.query.a,
        b: this.$encode(this.breadcrumb.slice(0, index + 1)),
        t: Date.now(),
      };
      if (this.$route.query.p) {
        query.p = this.$route.query.p;
      }
      this.$router.push({
        name: this.breadcrumb[index].r,
        query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-index {
  height: 100%;
  overflow: hidden;
  .page-nav {
    width: 200px;
    height: 100%;
    overflow: hidden;
    color: #ffffff;
    background-color: #304156;
    user-select: none;
    .nav-title {
      height: 60px;
      line-height: 1;
      color: #ffffff;
      font-size: 20px;
      font-weight: 600;
      padding: 0 25px;
      overflow: hidden;
    }
  }
  .page-header {
    height: 60px;
    padding: 0 20px;
    line-height: 1;
    overflow: hidden;
    border-bottom: 1px solid #ebebeb;
    .header-user {
      color: #1890ff;
    }
    .logout-button {
      margin-left: 20px;
    }
  }
  .page-breadcrumb {
    position: relative;
    left: -10px;
    overflow: hidden;
    line-height: 1;
    user-select: none;
    color: #1890ff;
    font-size: 14px;
    font-weight: 600;
    .breadcrumb-item {
      position: relative;
      padding: 10px;
      cursor: pointer;
      &:not(:first-child)::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 12px;
        background-color: #1890ff;
        left: -1px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .page-content {
    padding: 20px;
    overflow: hidden;
  }
}
</style>
