<template>
  <div class="app-page">
    <Header>
      <template slot="append">
        <el-button type="primary" :loading="loading" @click="handleSubmit">保存</el-button>
      </template>
    </Header>
    <div class="page-content">
      <el-form class="page-form" ref="form" :model="goodsData" label-width="120px">
        <el-form-item label="封面" required>
          <div class="flex flex-wrap">
            <el-upload
              class="image-uploader"
              v-for="(image, index) in fileList"
              :key="`${image.url}${index + 1}`"
              action="#"
              list-type="picture-card"
              accept="image/png,image/jpg,image/jpeg,image/gif"
              :show-file-list="false"
              :limit="9 - fileList.length"
              :multiple="true"
              :auto-upload="false"
              :on-change="handleChangeImage.bind(this, index)">
              <div class="uploader-image background-image" :style="{ backgroundImage: 'url(' + image.url + ')'}"></div>
              <div class="uploader-delete flex-align-justify" @click.stop="handleRemoveImage(index)">删除</div>
            </el-upload>
            <el-upload
              v-if="fileList.length < 9"
              class="image-upload"
              action="#"
              list-type="picture-card"
              accept="image/png,image/jpg,image/jpeg,image/gif"
              :show-file-list="false"
              :limit="9 - fileList.length"
              :multiple="true"
              :auto-upload="false"
              :on-change="handleChangeImage.bind(this, -1)">
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>
        <div class="flex">
          <el-form-item label="状态" required>
            <el-radio-group v-model="goodsData.status">
              <el-radio label="1">上架</el-radio>
              <el-radio label="2">下架</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="推荐到首页" required>
            <el-switch active-value="1" inactive-value="2" v-model="goodsData.in_home"></el-switch>
          </el-form-item>
        </div>
        <el-form-item label="编号" required>
          <el-input class="input-normal" v-model="goodsData.system_num"></el-input>
        </el-form-item>
        <el-form-item label="名称" required>
          <el-input class="input-big" type="textarea" resize="none" v-model="goodsData.name"></el-input>
        </el-form-item>
        <div class="flex">
          <el-form-item label="特别提示">
            <el-input class="input-normal" v-model="goodsData.des"></el-input>
          </el-form-item>
          <el-form-item label="渠道">
            <el-input class="input-normal" v-model="goodsData.channel"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="库存" required>
          <el-input-number v-model="goodsData.stock" controls-position="right" :min="0" :max="10000000" :step="1"></el-input-number>
        </el-form-item>
        <div class="flex">
          <el-form-item label="市面价" required>
            <el-input-number v-model="goodsData.price_old" controls-position="right" :min="0" :max="10000000" :step="0.01"></el-input-number>
          </el-form-item>
          <el-form-item label="分享价" required>
            <el-input-number v-model="goodsData.price_now" controls-position="right" :min="0" :max="10000000" :step="0.01"></el-input-number>
          </el-form-item>
          <el-form-item label="成本价" required>
            <el-input-number v-model="goodsData.price_true" controls-position="right" :min="0" :max="10000000" :step="0.01"></el-input-number>
          </el-form-item>
        </div>
        <el-form-item label="图文详情">
          <div class="flex">
            <div class="form-quill flex-one">
              <quill-editor
                class="quill-editor"
                ref="quillEditor"
                v-model="goodsData.info"
                :options="editorOption"
                @click.native="handleEnableEdit"
              />
              <el-upload
                class="quill-upload"
                action="#"
                list-type="picture-card"
                accept="image/png,image/jpg,image/jpeg,image/gif"
                :show-file-list="false"
                :limit="9"
                :multiple="true"
                :auto-upload="false"
                :on-change="handleChangeQuillImage">
                <el-button ref="quillUpload">上传</el-button>
              </el-upload>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { mapState } from 'vuex'
import { uploadService } from '../../services/common'
import { goodsInfoService, goodsUpdateService } from '../../services/goods'

export default {
  name: 'GoodsUpdate',
  components: { Header, quillEditor },
  data () {
    return {
      goodsData: {
        status: '1',
        in_home: '1',
        system_num: '',
        name: '',
        des: '',
        channel: '',
        stock: 1000,
        price_old: '',
        price_now: 15,
        price_true: '',
        info: ''
      },
      editorOption: {
        placeholder: '请输入内容',
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              // [{ font: [] }],
              [{ align: [] }],
              ['clean'],
              ['image']
            ],
            handlers: {
              'image': () => {
                this.$refs.quillUpload.$el.click()
              }
            }
          },
        }
      },
      fileList: [],
      quillImages: [],
      loading: false,
      qullInit: true
    }
  },
  computed: mapState(['authInfo']),
  methods: {
    async fetchData () {
      if (this.$route.query?.id) {
        const infoRes = await goodsInfoService({ goods_id: this.$route.query.id })
        if (infoRes && infoRes.data) {
          this.goodsData = {
            ...this.goodsData,
            ...infoRes.data,
            status: infoRes.data.status + '',
            in_home: infoRes.data.in_home + ''
          }
          const pic = infoRes.data?.pic && JSON.parse(infoRes.data.pic) ? JSON.parse(infoRes.data.pic) : []
          this.fileList = pic.map(item => {
            return { type: 'net', url: `${this.authInfo.file_url}/${item}` }
          })
        }
      }
    },
    handleRemoveImage (index) {
      this.fileList.splice(index, 1)
    },
    handleChangeImage (index, file) {
      if (index < 0) {
        this.fileList.push(file)
      } else {
        this.fileList.splice(index, 1, file)
      }
    },
    async handleChangeQuillImage (file) {
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const res = await uploadService({ file: file.raw })
      loading.close()
      if (res && res?.data?.url) {
        const quill = this.$refs.quillEditor.quill
        const pos = quill.getSelection().index
        quill.insertEmbed(pos, 'image', `${this.authInfo.file_url}/${res.data.url}`)
      } else if (res && (!res.data || !res.data.url)) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '上传图片出错'
        })
      }
    },
    handleEnableEdit () {
      if (this.qullInit) {
        const quill = this.$refs.quillEditor.quill
        if (!quill.hasFocus()) {
          quill.enable(true)
          quill.focus()
          const len = quill.getLength()
          quill.setSelection(len, 0)
        }
        this.qullInit = false
      }
    },
    async handleSubmit () {
      if (this.fileList.length === 0) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请至少上传一张图片'
        })
        return
      }
      if (!this.goodsData.system_num) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入编号'
        })
        return
      }
      if (!this.goodsData.name) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入名称'
        })
        return
      }
      // if (!this.goodsData.des) {
      //   this.$notify({
      //     title: '警告',
      //     type: 'warning',
      //     message: '请输入特别提示'
      //   })
      //   return
      // }
      if (!/^[0-9]+$/.test(this.goodsData.stock)) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入正确的库存'
        })
        return
      }
      if (!/^[0-9]+(.[0-9]{1,2})?$/.test(this.goodsData.price_old)) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入正确的市面价'
        })
        return
      }
      if (!/^[0-9]+(.[0-9]{1,2})?$/.test(this.goodsData.price_now)) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入正确的分享价'
        })
        return
      }
      if (!/^[0-9]+(.[0-9]{1,2})?$/.test(this.goodsData.price_true)) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入正确的成本价'
        })
        return
      }
      // if (!this.goodsData.info) {
      //   this.$notify({
      //     title: '警告',
      //     type: 'warning',
      //     message: '请输入图文详情'
      //   })
      //   return
      // }
      this.loading = true
      let pic = []
      for (let index = 0; index < this.fileList.length; index++) {
        const element = this.fileList[index]
        if (element?.type === 'net') {
          pic.push(element.url)
        } else {
          const res = await uploadService({ file: element.raw })
          if (res && res?.data?.url) {
            // this.fileList[index] = { type: 'net', url: res.data.url }
            pic.push(res.data.url)
          } else if (res && (!res.data || !res.data.url)) {
            this.$notify({
              title: '警告',
              type: 'warning',
              message: '上传图片出错'
            })
            this.loading = false
            return
          } else {
            return
          }
        }
      }
      const reg = new RegExp(`${this.authInfo.file_url}/`, 'g')
      pic = pic.map(item => {
        return item.replace(reg, '')
      })
      const res = await goodsUpdateService({ ...this.goodsData, pic, goods_id: this.$route.query?.id || '' })
      if (res) {
        this.$router.go(-1)
      }
      this.loading = false
    }
  },
  mounted () {
    this.$refs.quillEditor.quill.enable(false)

    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .quill-editor{
  .ql-container{
    min-height: 200px;
  }
}
.image-uploader{
  position: relative;
  overflow: hidden;
  margin: 0 10px 10px 0;
  &:hover{
    border-color: #409EFF;
    .uploader-delete{
      transform: translateY(0);
    }
  }
  .uploader-image{
    width: 100%;
    height: 100%;
  }
  .uploader-delete{
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    z-index: 10;
    color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    transition: all 0.15s;
    transform: translateY(100%);
    overflow: hidden;
  }
}
.page-form{
  .form-title{
    display: inline-block;
    color: #606266;
    font-size: 14px;
    width: 80px;
    text-align: right;
    margin-left: 10px;
  }
  .input-big{
    max-width: 500px;
  }
  .form-store{
    .store-item{
      margin-top: 15px;
    }
    .store-name{
      width: 205px;
      margin-right: 15px;
    }
  }
  .form-quill{
    position: relative;
    max-width: 1000px;
    overflow: hidden;
    padding-bottom: 20px;
    // .quill-editor{
    //   height: 800px;
    // }
    .quill-upload{
      height: 30px;
      opacity: 0;
      position: relative;
      z-index: -10;
    }
  }
}
</style>
