export function formatNumber(num, len = 2) {
  if (isNaN(parseFloat(num))) return 0
  return parseFloat(parseFloat(num).toFixed(len))
}

export function isDate(date) {
  if (date === null || date === undefined) return false
  if (isNaN(new Date(date).getTime())) return false
  if (Array.isArray(date)) return false
  return true
}

export function zeroFill(num) {
  return num >= 10 ? num : `0${num}`
}

const WEEKMAP = ['日', '一', '二', '三', '四', '五', '六']
const FORMATRULE = ['YYYY', 'YY', 'MM', 'M', 'DD', 'D', 'hh', 'h', 'mm', 'm', 'ss', 's', 'W', 'w']

export function formatDate(val, format = '') {
  if (typeof val === 'string') {
    if (/^\d{10}$/.test(val)) {
      val = parseInt(`${val}000`)
    } else if (/^\d{13}$/.test(val)) {
      val = parseInt(val)
    } else {
      val = val.replace(/-/g, '/')
    }
  } else if (typeof val === 'number' && /^\d{10}$/.test(val + '')) {
    val = val * 1000
  }
  const date = isDate(val) ? new Date(val) : new Date()
  if (format === 'date') return date
  if (format === 'timestamp') return date.getTime()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  const week = date.getDay()

  if (!format) return { year, month, day, hour, minute, second, week }

  for (let index = 0; index < FORMATRULE.length; index++) {
    const rule = FORMATRULE[index]
    const reg = new RegExp(rule, 'g')
    switch (rule) {
      case 'YYYY':
        format = format.replace(reg, year)
        break
      case 'YY':
        format = format.replace(reg, `${year}`.slice(2))
        break
      case 'MM':
        format = format.replace(reg, `${month >= 10 ? month : `0${month}`}`)
        break
      case 'M':
        format = format.replace(reg, month)
        break
      case 'DD':
        format = format.replace(reg, `${day >= 10 ? day : `0${day}`}`)
        break
      case 'D':
        format = format.replace(reg, day)
        break
      case 'hh':
        format = format.replace(reg, `${hour >= 10 ? hour : `0${hour}`}`)
        break
      case 'h':
        format = format.replace(reg, hour)
        break
      case 'mm':
        format = format.replace(reg, `${minute >= 10 ? minute : `0${minute}`}`)
        break
      case 'm':
        format = format.replace(reg, minute)
        break
      case 'ss':
        format = format.replace(reg, `${second >= 10 ? second : `0${second}`}`)
        break
      case 's':
        format = format.replace(reg, second)
        break
      case 'W':
        format = format.replace(reg, WEEKMAP[week])
        break
      case 'w':
        format = format.replace(reg, week === 0 ? 7 : week)
        break
      default:
        break
    }
  }
  return format
}