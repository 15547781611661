<template>
  <div class="app-page">
    <el-dialog title="发货" width="400px" :visible.sync="showSend">
      <el-form :model="sendForm">
        <el-form-item label="快递信息" label-width="100px">
          <el-radio-group v-model="sendForm.type">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="快递公司" label-width="100px">
          <el-input v-model="sendForm.express_name"></el-input>
        </el-form-item>
        <el-form-item label="快递单号" label-width="100px">
          <el-input v-model="sendForm.express_num"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showSend = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="handleConfirmSend">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="退款" width="400px" :visible.sync="showRefund">
      <el-form>
        <el-form-item label="退款类型" label-width="100px">
          <el-radio-group v-model="refundType">
            <el-radio label="1">线上退款</el-radio>
            <el-radio label="2">线下退款</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showRefund = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="handleConfirmRefund">确 定</el-button>
      </div>
    </el-dialog>
    <Search :config="SEARCHCONFIG" @change="handleSearch"></Search>
    <Pagination :config="page" @change="handlePagination"></Pagination>
    <div class="page-content">
      <Table :config="TABLECONFIG" :data="list">
        <template slot="slot" slot-scope="scope">
          <el-button
            class="warning"
            type="text"
            v-if="scope.data.detail_status === '200'"
            @click="handleShowSend(scope.data.order_num)"
          >发货</el-button>
          <!-- <el-button
            class="danger"
            type="text"
            v-if="['200', '300'].includes(scope.data.detail_status)"
            @click="handleRefund(scope.data.order_num)"
          >退款</el-button> -->
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Search from '../../components/Search.vue'
import Pagination from '../../components/Pagination.vue'
import Table from '../../components/Table.vue'
import { SEARCHCONFIG, TABLECONFIG } from '../../configs/order'
import { orderListService, orderRefundService, orderSendService } from '../../services/order'
import { formatNumber } from '../../utils/func'

export default {
  name: 'OrderList',
  components: { Search, Pagination, Table },
  data () {
    return {
      SEARCHCONFIG,
      TABLECONFIG,
      list: [],
      page: {
        page: 1,
        page_size: 20,
        total: 0
      },
      search: {},
      showSend: false,
      order_num: '',
      sendForm: {
        type: '1',
        express_num: '',
        express_name: ''
      },
      loading: false,
      showRefund: false,
      refundType: '1'
    }
  },
  computed: mapState(['authInfo']),
  methods: {
    ...mapMutations(['updateBreadcrumb']),
    async fetchData () {
      const res = await orderListService({ ...this.page, ...this.search })
      if (res && res.data) {
        res.data.items = Array.isArray(res.data?.items) ? res.data.items : []
        res.data.items.map(item => {
          item.detail_status = item.detail_status + ''
          if (['200'].includes(item.detail_status)) {
            item.detail_status_txt = `[danger]${item.detail_status_txt}`
          } else if (['300'].includes(item.detail_status)) {
            item.detail_status_txt = `[warning]${item.detail_status_txt}`
          } else if (['400'].includes(item.detail_status)) {
            item.detail_status_txt = `[success]${item.detail_status_txt}`
          } else if (['100', '500'].includes(item.detail_status)) {
            item.detail_status_txt = `[info]${item.detail_status_txt}`
          }
          item.amount = formatNumber(item.amount)
          item.goods_json = item.goods_json && JSON.parse(item.goods_json) ? JSON.parse(item.goods_json) : {}
          item.goods_pic = item.goods_json.pic && JSON.parse(item.goods_json.pic) && Array.isArray(JSON.parse(item.goods_json.pic)) ? JSON.parse(item.goods_json.pic) : []
          item.goods_pic = item.goods_pic.map(img => {
            return `${this.authInfo.file_url}/${img}`
          })
          item.goods_name = item.goods_json.name
          item.address_json = item.address_json && JSON.parse(item.address_json) ? JSON.parse(item.address_json) : {}
          item.address = `${item.address_json.name} ${item.address_json.phone}  ${item.address_json.province}${item.address_json.city}${item.address_json.area}${item.address_json.address}`
          item.member_phone = `${item.member_info?.nick_name || ''}${item.member_info?.nick_name && item.member_info?.phone ? '/' : '' }${item.member_info?.phone || ''}`
          item.express = item.express && JSON.parse(item.express) ? JSON.parse(item.express) : null
          item.express_info = item.express ? `${item.express.express_name} ${item.express.express_num}` : ''
        })
        this.list = Array.isArray(res.data?.items) ? res.data.items : []
        this.page.total = parseInt(res.data?.page_info?.total || 0)
      }
    },
    handleSearch (value) {
      this.search = value
      this.page.page = 1
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now()
        }
      })
    },
    handlePagination (value) {
      this.page = { ...this.page, ...value }
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now()
        }
      })
    },
    handleShowSend (order_num) {
      this.order_num = order_num
      this.showSend = true
    },
    async handleConfirmSend () {
      if (!this.sendForm.express_name) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入快递公司'
        })
        return
      }
      if (!this.sendForm.express_num) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入快递单号'
        })
        return
      }
      this.loading = true
      const res = await orderSendService({ order_num: this.order_num, ...this.sendForm })
      if (res) {
        this.showSend = false
        this.sendForm.express_name = ''
        this.sendForm.express_num = ''
        this.fetchData()
        this.$notify({
          title: '提示',
          type: 'success',
          message: '操作成功'
        })
      }
      this.loading = false
    },
    handleRefund (order_num) {
      this.order_num = order_num
      this.showRefund = true
    },
    async handleConfirmRefund () {
      this.loading = true
      const res = await orderRefundService({ order_num: this.order_num, type: this.refundType })
      if (res) {
        this.showRefund = false
        this.fetchData()
        this.$notify({
          title: '提示',
          type: 'success',
          message: '操作成功'
        })
      }
      this.loading = false
    },
  },
  mounted () {
    if (this.$route.query?.p) {
      const arr = this.$route.query.p.split('-')
      this.page.page_size = parseInt(arr[0] || 10)
      this.page.page = parseInt(arr[1] || 1)
    }

    this.fetchData()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>

</style>
