<template>
  <div class="app-page">
    <Pagination :config="page" @change="handlePagination">
      <template slot="prepend">
        <el-button type="primary" @click="handleTableOptions(undefined)">添加书单</el-button>
      </template>
    </Pagination>
    <div class="page-content">
      <Table :config="TABLECONFIG" :data="list" @options="handleTableOptions"></Table>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Pagination from '../../components/Pagination.vue'
import Table from '../../components/Table.vue'
import { TABLECONFIG } from '../../configs/group'
import { groupListService } from '../../services/group'

export default {
  name: 'GroupList',
  components: { Pagination, Table },
  data () {
    return {
      TABLECONFIG,
      list: [],
      page: {
        page: 1,
        page_size: 20,
        total: 0
      },
      search: {},
    }
  },
  methods: {
    ...mapMutations(['updateBreadcrumb']),
    async fetchData () {
      const res = await groupListService({ ...this.page, ...this.search })
      if (res && res.data) {
        res.data.items = Array.isArray(res.data?.items) ? res.data.items : []
        this.list = Array.isArray(res.data?.items) ? res.data.items : []
        this.page.total = parseInt(res.data?.page_info?.total || 0)
      }
    },
    handleSearch (value) {
      this.search = value
      this.page.page = 1
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now()
        }
      })
    },
    handlePagination (value) {
      this.page = { ...this.page, ...value }
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now()
        }
      })
    },
    handleTableOptions (value = {}) {
      const { id = '' } = value
      const { a = '', b = '' } = this.$route.query
      const breadcrumb = b ? this.$decode(b) : []
      breadcrumb.push({ t: '书单详情', r: 'group-update' })
      this.$router.push({
        name: 'group-update',
        query: {
          a,
          b: this.$encode(breadcrumb),
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now(),
          id
        }
      })
      this.updateBreadcrumb(breadcrumb)
    },
  },
  mounted () {
    if (this.$route.query?.p) {
      const arr = this.$route.query.p.split('-')
      this.page.page_size = parseInt(arr[0] || 10)
      this.page.page = parseInt(arr[1] || 1)
    }

    this.fetchData()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>

</style>
