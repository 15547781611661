<template>
  <div class="page-header flex-align">
    <div class="flex-one">
      <el-button
        type="primary"
        icon="el-icon-arrow-left"
        title="返回"
        circle
        plain
        @click="$router.go(-1)"
        v-if="showBack"
      ></el-button>
    </div>
    <slot name="append"></slot>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    showBack: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header{
  position: relative;
  top: -10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}
</style>
