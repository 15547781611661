export const SEARCHCONFIG = [
  {
    key: 'nick_name',
    type: 'input',
    label: '姓名/昵称',
    value: ''
  },
  {
    key: 'member_phone',
    type: 'input',
    label: '会员手机',
    value: ''
  },
  {
    key: 'inviter_phone',
    type: 'input',
    label: '合伙人手机',
    value: ''
  },
  {
    key: 'type',
    type: 'select',
    label: '状态',
    value: '',
    options: [{
      label: '全部',
      value: ''
    },
    {
      label: '普通会员',
      value: '1'
    },
    {
      label: '合伙人',
      value: '2'
    }]
  },
  {
    key: ['start_time', 'end_time'],
    type: 'datetimerange',
    label: '注册时间',
    value: ''
  }
]

export const TABLECONFIG = [
  {
    type: 'text',
    label: '姓名/昵称',
    prop: 'nick_name'
  },
  {
    type: 'text',
    label: '手机号',
    prop: 'phone'
  },
  {
    type: 'tag',
    label: '类型',
    prop: 'type_txt'
  },
  {
    type: 'text',
    label: '会员数量',
    prop: 'member_num'
  },
  {
    type: 'text',
    label: '单数',
    prop: 'inverter_order_num'
  },
  {
    type: 'text',
    label: '所属合伙人',
    prop: 'inverter_phone'
  },
  {
    type: 'text',
    label: '注册时间',
    prop: 'created_at'
  },
  {
    type: 'slot',
    label: '操作',
    width: 200,
    fixed: true
  },
]