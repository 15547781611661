export const BASE_URL = 'https://ledu-api.wetool.link'

export const API_AUTH = {
  login: '/api/login/admin'
}

export const API_COMMON = {
  upload: '/api/pc/upload',
  settingUpdate: '/api/pc/set/edit',
  settingInfo: '/api/pc/set/info'
}

export const API_GOODS = {
  list: '/api/pc/goods/list',
  info: '/api/pc/goods/info',
  update: '/api/pc/goods/edit'
}

export const API_GROUP = {
  list: '/api/pc/goods/group/list',
  info: '/api/pc/goods/group/info',
  update: '/api/pc/goods/group/edit'
}

export const API_MOBILE = {
  info: '/api/pc/phone/info',
  update: '/api/pc/phone/edit'
}

export const API_MEMBER = {
  list: '/api/pc/member/list',
  update: '/api/pc/member/edit',
  partner: '/api/pc/member/partner'
}

export const API_CHECK = {
  list: '/api/pc/image/list',
  check: '/api/pc/image/check'
}

export const API_ORDER = {
  list: '/api/pc/order/list',
  send: '/api/pc/order/express/out',
  refund: '/api/pc/order/refund'
}
