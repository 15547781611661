export const SEARCHCONFIG = [{
  key: 'system_num',
  type: 'input',
  label: '编号',
  value: ''
},
{
  key: 'name',
  type: 'input',
  label: '名称',
  value: ''
},
{
  key: 'status',
  type: 'select',
  label: '状态',
  value: '',
  options: [{
    label: '全部',
    value: ''
  },
  {
    label: '上架',
    value: '1'
  },
  {
    label: '下架',
    value: '2'
  }]
}
]

export const TABLECONFIG = [{
  type: 'image',
  label: '商品图片',
  prop: 'pic',
  width: 100
},
{
  type: 'text',
  label: '商品名称',
  prop: 'name',
  width: 200
},
{
  type: 'text',
  label: '编号',
  prop: 'system_num',
  width: 150
},
{
  type: 'text',
  label: '市面价',
  prop: 'price_old',
  width: 100
},
{
  type: 'text',
  label: '成本价',
  prop: 'price_true',
  width: 100
},
{
  type: 'text',
  label: '分享价',
  prop: 'price_now',
  width: 100
},
{
  type: 'text',
  label: '库存',
  prop: 'stock',
  width: 100
},
{
  type: 'text',
  label: '渠道',
  prop: 'channel',
  width: 100
},
{
  type: 'text',
  label: '已售',
  prop: 'sell_num',
  width: 100
},
{
  type: 'tag',
  label: '是否推荐至首页',
  prop: 'in_home',
  width: 120
},
{
  type: 'tag',
  label: '状态',
  prop: 'status',
  width: 100
},
{
  type: 'text',
  label: '最近上架时间',
  prop: 'updated_at',
  // width: 150
},
{
  type: 'slot',
  label: '操作',
  width: 120,
  fixed: true
},
]