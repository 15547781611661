<template>
  <div class="page-login flex-column-align">
    <div class="login-title">乐读图书</div>
    <div class="login-form">
      <div class="login-logo background-image"></div>
      <div class="form-content">
        <el-input
          class="login-input flex-align"
          size="middle"
          placeholder="账号"
          v-model="formData.phone"
        ></el-input>
        <el-input
          class="login-input flex-align"
          size="middle"
          placeholder="密码"
          show-password
          v-model="formData.passwd"
          @keyup.enter.native="handleLogin"
        ></el-input>
      </div>
      <el-button
        class="login-submit"
        type="primary"
        size="middle"
        :loading="loading"
        @click="handleLogin"
      >登录</el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { STORENAME } from '../utils/config'
import { loginService } from '../services/auth'

export default {
  name: 'Login',
  data() {
    return {
      formData: {
        phone: localStorage.getItem(`${STORENAME}phone`) || '',
        passwd: '',
      },
      loading: false
    };
  },
  methods: {
    ...mapMutations(['updateAuthInfo', 'updateActiveNav', 'updateBreadcrumb']),
    async handleLogin() {
      const { phone, passwd } = this.formData;
      if (!phone.replace(/(^\s*)|(\s*$)/, '')) {
        this.$notify({
          title: '警告',
          message: '请填写正确的账号',
          type: 'warning',
        })
        return
      }
      if (!passwd.replace(/(^\s*)|(\s*$)/, '')) {
        this.$notify({
          title: '警告',
          message: '请填写正确的密码',
          type: 'warning',
        })
        return
      }
      this.loading = true
      const res = await loginService(this.formData)
      if (res) {
        this.$router.replace({ name: 'goods-list' })
      }
      localStorage.setItem(`${STORENAME}phone`, phone)
      this.loading = false
    },
  },
  mounted() {
    this.updateAuthInfo(null)
    this.updateActiveNav('1')
    this.updateBreadcrumb(null)
  },
};
</script>

<style lang="scss" scoped>
::v-deep .login-input .el-input__inner{
  background-color: transparent;
  border: none;
  padding: 0;
  color: #ffffff;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ffffff;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #ffffff;
  }
}
.page-login {
  height: 100%;
  overflow: hidden;
  background: url(../assets/bg.png) repeat;
  .login-title {
    font-size: 40px;
    font-weight: 700;
    font-family: ClementePDai-Regular;
    color: #C64444;
    margin-top: 100px;
    letter-spacing: 20px;
  }
  .login-form{
    position: relative;
    margin: 125px 0;
    width: 450px;
    .form-content{
      width: 100%;
      background-color: #3B8590;
      border-radius: 5px;
      padding: 80px 50px;
    }
  }
  .login-logo{
    position: absolute;
    width: 155px;
    height: 155px;
    background-image: url(../assets/logo.png);
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  .login-input{
    width: 100%;
    height: 65px;
    padding: 20px;
    color: #ffffff;
    font-size: 20px;
    font-family: ClementePDac-ExtraLight;
    &:first-child{
      border-bottom: 1px solid #ffffff;
    }
    &:last-child{
      border-top: 1px solid #ffffff;
    }
  }
  .login-submit{
    width: 100%;
    height: 64px;
    margin-top: 20px;
    background-color: #C64444;
    border-color: #C64444;
    border-radius: 5px;
    font-size: 20px;
    font-family: ClementePDai-Regular;
    color: #ffffff;
  }
}
</style>
