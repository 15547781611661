import axios from 'axios'
import store from '../store'
// import router from '../router'
import NProgress from 'nprogress'
import {
  Notification
} from 'element-ui'
import {
  BASE_URL
} from './api'

function throwError(message) {
  Notification({
    title: '警告',
    message,
    type: 'error'
  })
  throw new Error(message)
}

function formatPostData(data) {
  if (data && Object.prototype.toString.call(data) !== '[object Object]') {
    return {}
  }
  let params = new FormData()
  for (let k in data) {
    if (Array.isArray(data[k])) {
      data[k].map((item) => {
        params.append(k + '[]', item)
      })
    } else {
      if (data[k] !== '') {
        params.append(k, data[k])
      }
    }
  }
  return params
}

const axiosIns = axios.create({
  baseURL: BASE_URL,
  headers: {
    'content-type': 'application/json'
  },
  timeout: 30000
})

axiosIns.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 300 && response.data.code === 200) {
      return Promise.resolve(response.data)
    } else {
      // if (response.data && (response.data.code === 401)) {
      //   router.replace('login')
      // }
      let message = response.data.msg || response.data.message || '请求错误'
      if (response.data.code === 112 && response.data.data) {
        message = response.data.data
      }
      throwError(message)
      return Promise.reject(response)
    }
  },
  error => {
    // if (error.response) {
    //   let status = parseInt(error.response.status || 0)
    //   if (status === 401) {
    //     router.replace('login')
    //   }
    // }
    let message = '请求错误'
    if (error.message) {
      message = error.message
    } else if (error.response && error.response.message) {
      message = error.response.message
    } else if (error.response && error.response.data && (error.response.data.message || error.response.data.msg)) {
      message = error.response.data.message || error.response.data.msg
    }
    throwError(message)
    return Promise.reject(error)
  }
)

export default {
  async request(options) {
    NProgress.start()
    return axiosIns({
      ...options
    }).then(data => {
      NProgress.done()
      return data
    }).catch(() => {
      NProgress.done()
    })
  },
  async get(options) {
    const {
      id = '', token = ''
    } = store.state.authInfo || {}
    return this.request({
      ...options,
      method: 'GET',
      data: {
        ...(options.data || {}),
        id,
        token
      }
    })
  },
  async post(options) {
    const {
      id = '', token = ''
    } = store.state.authInfo || {}
    return this.request({
      ...options,
      method: 'POST',
      data: {
        ...(options.data || {}),
        id,
        token
      }
    })
  },
  async upload(options) {
    const {
      id = '', token = ''
    } = store.state.authInfo || {}
    return this.request({
      ...options,
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
        ...options.headers
      },
      data: formatPostData({
        ...(options.data || {}),
        id,
        token
      })
    })
  }
}