import {
  API_CHECK
} from '../requests/api'
import Request from '../requests'

export async function checkListService(data) {
  return await Request.post({
    url: API_CHECK.list,
    data
  })
}

export async function checkCheckService(data) {
  return await Request.post({
    url: API_CHECK.check,
    data
  })
}