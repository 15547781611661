import Vue from 'vue'
import Router from 'vue-router'

import Index from './pages/Index.vue'
import Login from './pages/Login.vue'
import Home from './pages/Home.vue'
import Mobile from './pages/Mobile.vue'
import GoodsList from './pages/Goods/List.vue'
import GoodsUpdate from './pages/Goods/Update.vue'
import GroupList from './pages/Group/List.vue'
import GroupUpdate from './pages/Group/Update.vue'
import MemberList from './pages/Member/List.vue'
import OrderList from './pages/Order/List.vue'
import CheckList from './pages/Check/List.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    component: Index,
    children: [{
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/mobile',
      name: 'mobile',
      component: Mobile
    },
    {
      path: '/goods-list',
      name: 'goods-list',
      component: GoodsList
    },
    {
      path: '/goods-update',
      name: 'goods-update',
      component: GoodsUpdate
    },
    {
      path: '/group-list',
      name: 'group-list',
      component: GroupList
    },
    {
      path: '/group-update',
      name: 'group-update',
      component: GroupUpdate
    },
    {
      path: '/check-list',
      name: 'check-list',
      component: CheckList
    },
    {
      path: '/order-list',
      name: 'order-list',
      component: OrderList
    },
    {
      path: '/member-list',
      name: 'member-list',
      component: MemberList
    }]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
  ]
})