import { API_MEMBER } from '../requests/api'
import Request from '../requests'

export async function memberListService(data) {
  return await Request.post({
    url: API_MEMBER.list,
    data
  })
}

export async function memberUpdateService(data) {
  return await Request.post({
    url: API_MEMBER.update,
    data
  })
}

export async function memberPartnerService(data) {
  return await Request.post({
    url: API_MEMBER.partner,
    data
  })
}
