import { API_ORDER } from '../requests/api'
import Request from '../requests'

export async function orderListService(data) {
  return await Request.post({
    url: API_ORDER.list,
    data
  })
}

export async function orderRefundService(data) {
  return await Request.post({
    url: API_ORDER.refund,
    data
  })
}

export async function orderSendService(data) {
  return await Request.post({
    url: API_ORDER.send,
    data
  })
}