export const SEARCHCONFIG = [
  {
    key: 'name',
    type: 'input',
    label: '名称',
    value: ''
  }
]

export const TABLECONFIG = [
  {
    type: 'text',
    label: '书单名称',
    prop: 'name'
  },
  {
    type: 'text',
    label: '书籍数量',
    prop: 'num'
  },
  {
    type: 'text',
    label: '最近修改时间',
    prop: 'updated_at'
  },
  {
    type: 'options',
    label: '操作',
    width: 200,
    options: [
      { label: '编辑' }
    ]
  },
]