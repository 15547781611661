import { API_COMMON } from '../requests/api'
import Request from '../requests'

export async function uploadService(data) {
  return await Request.upload({
    url: API_COMMON.upload,
    data
  })
}

export async function settingUpdateService(data) {
  return await Request.post({
    url: API_COMMON.settingUpdate,
    data
  })
}

export async function settingInfoService(data) {
  return await Request.post({
    url: API_COMMON.settingInfo,
    data
  })
}