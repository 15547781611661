import { API_AUTH } from '../requests/api'
import Request from '../requests'
import store from '../store'

export async function loginService(data) {
  const res = await Request.post({
    url: API_AUTH.login,
    data
  })
  if (res && res.data) {
    store.commit('updateAuthInfo', res.data)
  }
  return res
}