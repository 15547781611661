<template>
  <div class="page-pagination flex flex-align-start">
    <div class="flex-one">
      <slot name="prepend"></slot>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="config.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="config.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="config.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          page: 1,
          page_size: 10,
          total: 0
        }
      }
    }
  },
  methods: {
    handleSizeChange (value) {
      const { page } = this.config
      this.$emit('change', { page, page_size: value }, 'size')
    },
    handleCurrentChange (value) {
      const { page_size } = this.config
      this.$emit('change', { page: value, page_size }, 'current')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-pagination{
  margin-bottom: 10px;
}
</style>
