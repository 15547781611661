import {
  API_GOODS
} from '../requests/api'
import Request from '../requests'

export async function goodsListService(data) {
  return await Request.post({
    url: API_GOODS.list,
    data
  })
}

export async function goodsInfoService(data) {
  return await Request.post({
    url: API_GOODS.info,
    data
  })
}

export async function goodsUpdateService(data) {
  return await Request.post({
    url: API_GOODS.update,
    data
  })
}