<template>
  <div class="app-page">
    <Search :config="SEARCHCONFIG" @change="handleSearch"></Search>
    <Pagination :config="page" @change="handlePagination"></Pagination>
    <div class="page-content">
      <Table :config="TABLECONFIG" :data="list">
        <template slot="slot" slot-scope="scope">
          <el-button
            class="success"
            type="text"
            @click="handlePass(scope.data.id, '1')"
            v-if="['1', '3'].includes(scope.data.status)"
          >通过</el-button>
          <el-button
            class="danger"
            type="text"
            @click="handlePass(scope.data.id, '2')"
            v-if="scope.data.status === '1'"
          >不通过</el-button>
        </template>
      </Table>
    </div>
    <el-dialog
      width="400px"
      title="审核不通过"
      :visible.sync="showCheck"
      @close="showCheck = false"
    >
      <el-form class="flex-one" label-width="100px">
        <el-form-item label="备注" required>
          <el-input
            v-model="remark"
            type="textarea"
            :rows="2"
            style="width: 400px;"
            resize="none"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showCheck = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="handleSubmitCheck">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Search from '../../components/Search.vue'
import Pagination from '../../components/Pagination.vue'
import Table from '../../components/Table.vue'
import { SEARCHCONFIG, TABLECONFIG } from '../../configs/check'
import { checkListService, checkCheckService } from '../../services/check'

const STATUSMAP = {
  '1': 'primary',
  '2': 'success',
  '3': 'danger'
}

export default {
  name: 'CheckList',
  components: { Search, Pagination, Table },
  data () {
    return {
      SEARCHCONFIG,
      TABLECONFIG,
      list: [],
      page: {
        page: 1,
        page_size: 20,
        total: 0
      },
      search: {},
      showCheck: false,
      checkId: '',
      remark: '',
      loading: false
    }
  },
  computed: mapState(['authInfo']),
  methods: {
    ...mapMutations(['updateBreadcrumb']),
    async fetchData () {
      const res = await checkListService({ ...this.page, ...this.search })
      if (res && res.data) {
        res.data.items = Array.isArray(res.data?.items) ? res.data.items : []
        res.data.items.map(item => {
          item.status = item.status + ''
          item.status_txt = `[${STATUSMAP[item.status]}]${item.status_txt}`
          item.pic = [`${this.authInfo.file_url}/${item.pic}`]
          item.member_name = item?.member_info?.nick_name
          item.member_phone = item?.member_info?.phone
          item.inverter_name = item?.partner_member_info?.nick_name
          item.inverter_phone = item?.partner_member_info?.phone
        })
        this.list = Array.isArray(res.data?.items) ? res.data.items : []
        this.page.total = parseInt(res.data?.page_info?.total || 0)
      }
    },
    handleSearch (value) {
      this.search = value
      this.page.page = 1
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now()
        }
      })
    },
    handlePagination (value) {
      this.page = { ...this.page, ...value }
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now()
        }
      })
    },
    async handlePass (image_id, type) {
      const res = await checkCheckService({ image_id, type })
      if (res) {
        this.fetchData()
        this.$notify({
          title: '提示',
          type: 'success',
          message: '操作成功'
        })
      }
    },
    handleUnPass (id) {
      this.checkId = id
      this.showCheck = true
    },
    async handleSubmitCheck() {
      if (!this.remark) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入备注'
        })
        return
      }
      this.loading = true
      const res = await checkCheckService({ image_id: this.checkId, type: '1', check_info: this.remark })
      this.loading = false
      if (res) {
        this.showCheck = false
        this.fetchData()
        this.$notify({
          title: '提示',
          type: 'success',
          message: '操作成功'
        })
      }
    }
  },
  async mounted () {
    if (this.$route.query?.p) {
      const arr = this.$route.query.p.split('-')
      this.page.page_size = parseInt(arr[0] || 10)
      this.page.page = parseInt(arr[1] || 1)
    }

    this.fetchData()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>

</style>
