export const SEARCHCONFIG = [
  {
    key: 'member_phone',
    type: 'input',
    label: '会员手机',
    value: ''
  },
  {
    key: 'inviter_phone',
    type: 'input',
    label: '合伙人手机',
    value: ''
  },
  {
    key: 'status',
    type: 'select',
    label: '状态',
    value: '',
    options: [{
      label: '全部',
      value: ''
    },
    {
      label: '待审核',
      value: '1'
    },
    {
      label: '已通过',
      value: '2'
    },
    {
      label: '未通过',
      value: '3'
    }]
  },
  {
    key: ['start_time', 'end_time'],
    type: 'datetimerange',
    label: '注册时间',
    value: ''
  }
]

export const TABLECONFIG = [
  {
    type: 'image',
    label: '分享截图',
    prop: 'pic',
    width: 100
  },
  {
    type: 'text',
    label: '会员手机号',
    prop: 'member_phone',
    width: 120
  },
  {
    type: 'text',
    label: '会员姓名/昵称',
    prop: 'member_name',
    width: 120
  },
  {
    type: 'text',
    label: '所属合伙人手机',
    prop: 'inverter_phone',
    width: 120
  },
  {
    type: 'text',
    label: '所属合伙人姓名/昵称',
    prop: 'inverter_name',
    width: 160
  },
  {
    type: 'tag',
    label: '状态',
    prop: 'status_txt'
  },
  // {
  //   type: 'text',
  //   label: '备注',
  //   prop: 'check_info',
  //   width: 160
  // },
  {
    type: 'text',
    label: '提交时间',
    prop: 'updated_at'
  },
  {
    type: 'slot',
    label: '操作',
    width: 140,
    fixed: true
  },
]