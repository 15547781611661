<template>
  <div class="page-search flex flex-align-start">
    <div class="search-content flex-one flex flex-wrap">
      <div class="search-item flex-align" v-for="(item, index) in search" :key="`${item.type}${index}`">
        <div class="item-label">{{item.label}}</div>
        <el-input
          class="item-input"
          v-model="item.value"
          :placeholder="item.placeholder || '请输入'"
          :maxlength="item.maxlength || ''"
          :show-word-limit="item.maxlength !== undefined"
          v-if="item.type === 'input'"
        >
        </el-input>
        <el-select
          class="item-input"
          v-model="item.value"
          :placeholder="item.placeholder || '请选择'"
          v-else-if="item.type === 'select'"
        >
          <el-option
            v-for="opt in item.options"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value">
          </el-option>
        </el-select>
        <el-date-picker
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="item.value"
          v-else-if="item.type === 'datetimerange'">
        </el-date-picker>
      </div>
    </div>
    <template v-if="search.length > 0">
      <el-button type="info" plain @click="handleReset">重置</el-button>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
    </template>
  </div>
</template>

<script>
import { formatDate } from '../utils/func'

export default {
  name: 'Search',
  props: {
    config: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      search: JSON.parse(JSON.stringify(this.config)),
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      datetimeRangeValue: ''
    }
  },
  computed: {
    _search () {
      return JSON.parse(JSON.stringify(this.config))
    }
  },
  watch: {
    _search: function (value) {
      this.search = JSON.parse(JSON.stringify(value))
    }
  },
  methods: {
    formatConfig () {
      let obj = {}
      for (let index = 0; index < this.search.length; index++) {
        const element = this.search[index]
        if (element.type === 'datetimerange') {
          obj[element.key[0]] = element.value ? formatDate(element.value[0], 'YYYY-MM-DD hh:mm:ss') : ''
          obj[element.key[1]] = element.value ? formatDate(element.value[1], 'YYYY-MM-DD hh:mm:ss') : ''
        } else {
          obj[element.key] = element.value
        }
      }
      return obj
    },
    handleReset () {
      this.search = JSON.parse(JSON.stringify(this._search))
      this.$emit('change', this.formatConfig(), 'reset')
    },
    handleSearch () {
      this.$emit('change', this.formatConfig(), 'submit')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-search{
  overflow: hidden;
  .search-item{
    margin: 0 20px 20px 0;
    .item-label{
      color: #999999;
      font-weight: 700;
      min-width: 60px;
      padding-right: 10px;
    }
    .item-input{
      width: 200px;
    }
  }
}
</style>
