<template>
  <el-table
    :data="data"
    height="100%"
  >
    <el-table-column
      v-for="(item, index) in config"
      :key="index"
      :label="item.label || ''"
      :prop="item.prop && ['text'].includes(item.type) ? item.prop : ''"
      :width="item.width || ''"
      :show-overflow-tooltip="item.type === 'text'"
      :fixed="item.type === 'options' || item.fixed ? 'right' : false"
    >
      <template slot-scope="scope">
        <template v-if="['text'].includes(item.type)">{{ scope.row[item.prop] }}</template>
        <el-tag
          v-else-if="item.type === 'tag' && scope.row[item.prop]"
          :type="(scope.row[item.prop] + '').replace(/^\[|\].*$/g, '')"
        >{{(scope.row[item.prop] + '').replace(/^\[.*\]/, '')}}</el-tag>
        <template v-else-if="item.type === 'options' && Array.isArray(item.options)">
          <el-button
            v-for="(button, idx) in item.options"
            type="text"
            :class="button.class || ''"
            :key="`${index}${idx}`"
            @click="handleClick(scope.row, idx, index)"
          >{{ button.label }}</el-button>
        </template>
        <el-image
          v-else-if="item.type === 'image' && scope.row[item.prop]"
          style="width: 36px; height: 50px"
          :src="scope.row[item.prop][0]" 
          :preview-src-list="scope.row[item.prop]">
        </el-image>
        <template v-else-if="item.type === 'slot'">
          <slot name="slot" :data="scope.row"></slot>
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'Table',
  props: {
    config: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    handleClick (data, idx, index) {
      this.$emit('options', data, idx, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-table{
  .table-button{
    &:not(:first-child){
      margin-left: 20px;
    }
  }
}
</style>
