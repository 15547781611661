<template>
  <div class="app-page">
    <el-dialog
      width="400px"
      title="编辑会员"
      :visible.sync="showUpdate"
      @close="showUpdate = false"
    >
      <el-form class="flex-one" :model="formData" label-width="100px">
        <el-form-item label="姓名/昵称" required>
          <el-input v-model="formData.nick_name" style="width: 400px;" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formData.phone" style="width: 400px;" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showUpdate = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="handleSubmitUpdate">确 定</el-button>
      </div>
    </el-dialog>
    <Search :config="SEARCHCONFIG" @change="handleSearch"></Search>
    <Pagination :config="page" @change="handlePagination"></Pagination>
    <div class="page-content">
      <Table :config="TABLECONFIG" :data="list">
        <template slot="slot" slot-scope="scope">
          <el-button type="text" @click="handleShowUpdate(scope.data)">编辑</el-button>
          <el-button
            :class="scope.data.type === '1' ? 'warning' : 'danger'"
            type="text"
            @click="handleChangeType(scope.data.id, scope.data.type)"
          >{{ scope.data.type === '1' ? '设为合伙人' : '取消合伙人' }}</el-button>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Search from '../../components/Search.vue'
import Pagination from '../../components/Pagination.vue'
import Table from '../../components/Table.vue'
import { SEARCHCONFIG, TABLECONFIG } from '../../configs/member'
import { memberListService, memberPartnerService, memberUpdateService } from '../../services/member'

export default {
  name: 'MemberList',
  components: { Search, Pagination, Table },
  data () {
    return {
      SEARCHCONFIG,
      TABLECONFIG,
      list: [],
      page: {
        page: 1,
        page_size: 20,
        total: 0
      },
      search: {},
      showUpdate: false,
      loading: false,
      formData: {
        member_id: '',
        nick_name: '',
        phone: ''
      }
    }
  },
  methods: {
    async fetchData () {
      const res = await memberListService({ ...this.page, ...this.search })
      if (res && res.data) {
        res.data.items = Array.isArray(res.data?.items) ? res.data.items : []
        res.data.items.map(item => {
          item.type = item.type + ''
          item.type_txt = item.type === '1' ? '[success]普通会员' : '[warning]合伙人'
          item.inverter_phone = `${item.inverter_info?.nick_name || ''}${item.inverter_info?.nick_name && item.inverter_info?.phone ? '/' : '' }${item.inverter_info?.phone || ''}`
        })
        this.list = Array.isArray(res.data?.items) ? res.data.items : []
        this.page.total = parseInt(res.data?.page_info?.total || 0)
      }
    },
    handleSearch (value) {
      this.search = value
      this.page.page = 1
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now()
        }
      })
    },
    handlePagination (value) {
      this.page = { ...this.page, ...value }
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          p: `${this.page.page_size}-${this.page.page}`,
          t: Date.now()
        }
      })
    },
    handleChangeType (member_id, type) {
      this.$confirm(`确认${type === '1' ? '设为' : '取消'}合伙人`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await memberPartnerService({ member_id })
        if (res) {
          this.fetchData()
          this.$notify({
            title: '提示',
            type: 'success',
            message: '操作成功'
          })
        }
      })
    },
    handleShowUpdate (value) {
      const { id, nick_name, phone } = value
      this.formData = {
        member_id: id,
        nick_name,
        phone
      }
      this.showUpdate = true
    },
    async handleSubmitUpdate () {
      if (!this.formData.nick_name) {
        this.$notify({
          title: '警告',
          type: 'warning',
          message: '请输入姓名/昵称'
        })
        return
      }
      this.loading = true
      const res = await memberUpdateService(this.formData)
      this.loading = false
      if (res) {
        this.showUpdate = false
        this.fetchData()
        this.$notify({
          title: '提示',
          type: 'success',
          message: '操作成功'
        })
      }
    }
  },
  mounted () {
    if (this.$route.query?.p) {
      const arr = this.$route.query.p.split('-')
      this.page.page_size = parseInt(arr[0] || 10)
      this.page.page = parseInt(arr[1] || 1)
    }

    this.fetchData()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>

</style>
